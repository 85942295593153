import React from 'react';

import { loadableReady } from 'nordic/lazy';
import { ImageProvider } from 'nordic/image';
import { hydrateRoot } from 'nordic/hydrate';

import SellersView from '../../pages/landing/sellersView';
import * as hotjar from '../scripts/hotjar';
import * as eventTracker from '../scripts/custom-event-tracking';
import { sendViewTrack } from '../scripts/custom-view-tracking';

/**
 * Styles
 */
import '../../pages/landing/styles.mobile.scss';

const { AndesProvider } = require('@andes/context');

const props = window.__PRELOADED_STATE__;

const initCallback = () => {
  const {
    landingConfig: {
      tracking: { melidata },
    },
  } = window.__PRELOADED_STATE__;

  sendViewTrack();
  eventTracker.initEventTracking(melidata);
  hotjar.initEventTracking();
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
loadableReady(() => {
  hydrateRoot(
    document.getElementById('root-app'),
    <AndesProvider locale={props?.locale} device={props?.device}>
      <ImageProvider prefix={props?.imagesPrefix}>
        <SellersView {...props} initCallback={initCallback} />
      </ImageProvider>
    </AndesProvider>,
  );
});
